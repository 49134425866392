import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export class Teaser extends Component {

    render(){

        const 
            { data, type, url } = this.props,
            className = 'teaser pense-futuro style--' + ( type === undefined ? '1' : type ) + ( data.custom.thumb ? ' with-photo' : '' ),
            itemID = ( data.ID === undefined ? data.id : data.ID )
        ;
        
        return(
        <li key={'teaser-'+itemID} data-id={itemID} className={className}>
            {data.custom.thumb && (
                data.custom.external_url
                ?
                <a href={data.custom.external_url} className="image-wrapper" target="_blank" rel="noreferrer" title={data.title.rendered}>
                    <picture><img src={data.custom.thumb} alt={data.title.rendered} /></picture>
                </a>
                :
                <NavLink to={url} className="image-wrapper" title={data.title.rendered}>
                    <picture><img src={data.custom.thumb} alt={data.title.rendered} /></picture>
                </NavLink>
            )}
            <div>
                <span className="date-editoria">
                    <strong>{data.custom.date}</strong>
                    {data.custom.editoria && <em>{data.custom.editoria.name}</em>}
                </span>
                { data.custom.external_url 
                    ?
                    <a href={data.custom.external_url} target="_blank" rel="noreferrer" title={data.title.rendered}>
                        <h4 className="title">{data.title.rendered}</h4>
                        <p className="desc">{data.custom.excerpt}</p>
                    </a>
                    :
                    <NavLink to={url} title={data.title.rendered}>
                        <h4 className="title">{data.title.rendered}</h4>
                        <p className="desc">{data.custom.excerpt}</p>
                    </NavLink>
                }
            </div>
        </li>);

    }

}

export default Teaser