import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Slider from "react-slick";

export class PenseFuturoLasts extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            items: []
        }
    
    }

    componentDidMount (){

        const 
            SERVER_URL = process.env.REACT_APP_SERVER_URL,
            URL_DESTAQUES = SERVER_URL + 'wp-json/bbprev/v1/beneficios-destaques'
        ;
        
        axios
            .get( URL_DESTAQUES )
            .then(res => this.setState({
                isLoaded: true,
                items: res.data
            }))
            .catch(err => console.log(err))
        ;
        
    }

    render() {

        const { items, isLoaded } = this.state;
        if( !isLoaded || !items.length ) return null;

        const total = items.length;
        const slideCount = total < 3 ? total : 3;
        const totalDots = Math.ceil( total / 3 );

        var slickSettings;
        
        if(  slideCount === 1 ){
            slickSettings = {};
        }
        else if( slideCount === 2 ){
            slickSettings = {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
                dots: true,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 840,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: true
                        }
                    }
                ]
            };
        }
        else {
           slickSettings = {
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true,
                dots: true,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1080,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 820,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: true,
                            addaptiveHeight: true
                        }
                    }
                ]
            };
        }

        return (
        <section className="beneficios-destaques">
            <h3>Destaques</h3>
            <div className="slider-wrapper">
                <Slider {...slickSettings} className={ 'slider dots--' + totalDots }>
                    {items.map( item =>
                        <a href={item.custom.url} key={item.id} data-id={item.id} className="destaque-item" target="_blank" rel="noreferrer noopener">
                            {item.custom.thumb && 
                            <span className="image-wrapper">
                                <img src={item.custom.thumb} alt=" " />
                            </span>}
                            <span className="content">
                                <h4>{item.post_title}</h4>
                                <em>{item.post_excerpt}</em>
                            </span>
                            <button className="saiba-mais">
                                <strong>Saiba mais</strong>
                                <i></i>
                            </button>
                        </a>
                    )}
                </Slider>
            </div>
        </section>
        )

    }

}

export default withRouter( PenseFuturoLasts );