import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { penseFuturoLink } from '../helpers/Common';

export class Relateds extends Component {
    
    constructor( props ){
        super();
    }
    render(){

        const { items } = this.props;

        return (
            <section className="teasers-list pense-futuro-lasts">
                <div>
                    <header>
                        <h3>Conteúdos Relacionados</h3>
                    </header>
                    <ul>
                        { items.map( ( item ) => {

                            const url = penseFuturoLink( item );

                            return(
                            <li key={item.ID} data-id={item.ID} className={ 'teaser pense-futuro style--2' + ( item.custom.thumb ? ' with-image' : '' ) }>
                                {item.custom.thumb && 
                                <NavLink to={url} title={item.post_title}>
                                    <picture><img src={item.custom.thumb} alt={item.post_title} /></picture>
                                </NavLink>}
                                <span>
                                    <strong>{item.custom.date}</strong>
                                    {item.custom.editoria && <em>{item.custom.editoria.name}</em> }
                                </span>
                                <NavLink to={url} title={item.post_title}>
                                    <h4 className="title">{item.post_title}</h4>
                                    <p className="desc">{item.custom.excerpt}</p>
                                </NavLink>
                            </li>
                            );
                        } ) }
                    </ul>
                </div>
            </section>
        );      
    }

}

export default Relateds;
