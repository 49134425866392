import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export class Glossario extends Component {

    render(){

        return(
            <section className="widget-glossario">
                <h3>Traduzimos a Previdência Privada para você</h3>
                <em>Acesse o dicionário da BB Previdência e entenda tudo sobre os termos do mundo financeiro.</em>
                <NavLink to="/pensefuturo/glossario" className="button default" title="Acessar Glossário">
                    Acessar Glossário
                </NavLink>
            </section>
        )

    }

}

export default Glossario;