import React, { Component } from 'react';
import axios from 'axios';
import { API } from '../helpers/API';
import { penseFuturoLink } from '../helpers/Common';
import Teaser from '../blocks/Teaser';

export class PenseFuturo extends Component {

    state = {
        title: '',
        data : false,
        isLoaded: false,
        items: []
    }

    async componentDidMount () {

        var { data } = this.props;
        if( data === undefined || data.title === '' ){
            const homeData = await API.Common.getHomeData();
            data = homeData.pensefuturo;
        }
        this.setState({ data });

        // Get items
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        axios.get( SERVER_URL + 'wp-json/bbprev/v1/pense-futuro-destaques?siteID=' + window.siteID + '&type=1' )
            .then( ( res ) => this.setState({
                isLoaded: true,
                items: res.data
            }))
            .catch(err => console.log(err))
        ;
        
    }

    render(){
        
        const 
            { data, items, isLoaded } = this.state,
            API_URL = process.env.REACT_APP_API_URL
        ;
        if( !isLoaded || !items.length ) return null;

        return (
            <React.Fragment>
                <section id="pensefuturo" className="pense-futuro">
                    <header>
                        <div>
                            <h3>{data.title}</h3>
                            <em>{data.chapeu}</em>
                            <p>{data.desc}</p>
                        </div>
                    </header>
                    <div>
                        <h4>Conteúdos em destaque</h4>
                        <ul>
                            {items.map( ( item ) => {
                                const url = penseFuturoLink( item );
                                return (
                                    <Teaser key={item.custom.slug} data={item} url={url} type="1"></Teaser>
                                );
                            })}
                        </ul>
                        <a href={ API_URL + "/pensefuturo" } className="button" title="Acesse o Pense Futuro">
                            Acesse o Pense Futuro
                        </a>
                    </div>
                </section>
                <style>
                    {data.image && '#pensefuturo>header{background-image:url('+data.image+');}'}
                    {data.image_mobile && '@media(max-width:540px){#pensefuturo>header{background-image:url('+data.image_mobile+');}}'}
                </style>
            </React.Fragment>
        )

    }
    
}

export default PenseFuturo;