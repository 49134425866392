import React, { Component } from 'react'
import PropTypes from "prop-types";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { imageURL } from '../helpers/Common';

//import Newsletter from '../blocks/Newsletter';
import Networks from '../blocks/Networks';
import LinksUteis from '../blocks/LinksUteis';
import Disclaimer from '../blocks/Disclaimer';
import Icons from '../helpers/Icons.js';

// import appStore from '../../assets/images/app-store@2x.png';
// import googlePlay from '../../assets/images/google-play@2x.png';

export class Footer extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super();

        this.state = {
            isLoaded: false,
            data: null
        };

    }

    componentDidMount(){
    
        // Footer data
        const API_URL = process.env.REACT_APP_API_URL;
        axios
            .get( API_URL + '/data/footer.json' )
            .then( ( res ) => {
                this.setState({
                    isLoaded: true,
                    data: res.data
                });
            })
            .catch( err => console.log( err ) )
        ;

        // Neoassist
        const neoPlano = this.props.siteData.name;
        window.NeoAssistTag = {};
        window.NeoAssistTag.querystring = true;
        window.NeoAssistTag.pageid = '';
        window.NeoAssistTag.clientdomain = 'bbprevidencia.neoassist.com';
        window.NeoAssistTag.initialize = {
            fields: {
                ProtocoloFieldfieldA: neoPlano,
                ProtocoloFieldB: ''
            }
        };
        var na = document.createElement('script'); 
        na.type = 'text/javascript'; 
        na.async = true; 
        na.src = 'https://cdn.atendimen.to/n.js'; 
        var s = document.getElementsByTagName( 'script' )[ 0 ]; 
        s.parentNode.insertBefore( na, s ); 

    }

    render() {
        
        const { data, isLoaded } = this.state;
        if( !isLoaded ) return null;

        const { id, name, simule, adesao, pages } = this.props.siteData;
        const { location } = this.props;
        const isHome = window.isHome;
        
        return (
            <footer className="App-footer">
                <div className="wcf">
                    <div>
                        <h1>BB Previdência</h1>
                        <Networks data={data.networks} />
                        <div className="menu">
                            { 
                            !id 
                            ? null
                            : <div>
                                <h2>Mapa do Site</h2>
                                {
                                    isHome
                                    ?
                                    <nav>
                                        {pages.map(page=>
                                            <NavLink to={ '/' + page.slug } onClick={this.menuClose} activeClassName='is-active' key={page.id}>{page.title}</NavLink>
                                        )}
                                    </nav>
                                    :
                                    <nav>
                                        <NavLink to="/#o-plano" onClick={this.menuClose}>{name}</NavLink>
                                        <NavLink to="/#beneficios" onClick={this.menuClose}>Benefícios</NavLink>
                                        { !isHome && simule.code ? <NavLink to="/#simuladores" onClick={this.menuClose}>Simule agora</NavLink> : null }
                                        { !isHome && adesao.code ? <NavLink to="/#simuladores" onClick={this.menuClose}>Faça sua adesão</NavLink> : null }
                                    </nav>
                                }
                                <nav>
                                    {/*<NavLink to="/#noticias" onClick={this.menuClose}>Notícias</NavLink>*/}
                                    <NavLink to="/fale-conosco" onClick={this.menuClose}>Fale conosco</NavLink>
                                    <NavLink to="/#comunicados" onClick={this.menuClose}>Comunicados</NavLink>
                                    <NavLink to="/pensefuturo" onClick={this.menuClose}>Pense Futuro</NavLink>
                                    {/* <NavLink to="/#educacao-financeira" onClick={this.menuClose}>Educação Financeira</NavLink> */}
                                    <NavLink to="/#central-de-informacoes" onClick={this.menuClose}>Central de Informações</NavLink>
                                </nav>
                            </div>
                            }
                            <LinksUteis siteID={id} />
                        </div>
                        {/* <Newsletter siteID={id} name={name} /> */}
                    </div>
                    {location.pathname !== '/fale-conosco' &&
                    <div>
                        <h2>Fale com a gente por onde preferir</h2>
                        <ul>
                            <li>
                                <i class="icon"><Icons tag="phone2" /></i>
                                <nav>
                                    <a href="tel:08006014554" title="Fazer ligação" target="_blank" rel="noreferrer">0800 601 4554 </a>
                                    <a href="tel:30043444" title="Fazer ligação" target="_blank" rel="noreferrer"> 3004 3444</a>
                                </nav>
                            </li>
                            <li>
                                <i class="icon"><Icons tag="whatsapp" /></i>
                                <strong>Mande uma mensagem para o nosso WhatsApp: <a href="https://wa.me/556131810179" title="WhatsApp" target="_blank" rel="noreferrer">(61) 3181-0179</a></strong>
                            </li>
                        </ul>
                        {/*<div>
                            <h3>Baixe nosso Aplicativo</h3>
                            <nav>
                                <a href={data.googlePlay} title="App Store" className="app-store" target="_blank" rel="noreferrer">
                                    <img src={appStore} alt="App Store" />
                                </a>
                                <a href={data.googlePlay} title="Google Play" className="google-play" target="_blank" rel="noreferrer">
                                    <img src={googlePlay} alt="Google Play" />
                                </a>
                            </nav>
                        </div>*/}
                    </div>}
                    <div>
                        <p>BB Previdência - Fundo de Pensão Banco do Brasil</p>
                        <p>{data.endereco}</p>
                        {
                            data.logos
                            ? <p className="logos">{data.logos.map( ( logo ) => {
                                const urlImage = imageURL( logo.logo );
                                if( logo.url ){
                                    return <a href={logo.url} title={logo.label} target="_blank" rel="noreferrer" key={logo.id}><img src={urlImage} alt={logo.label} key={logo.id} /></a>
                                }
                                else {
                                    return <img src={urlImage} alt={logo.label} key={logo.id} />
                                }
                            })} </p>
                            : null
                        }
                        <div className="small rules">
                            {ReactHtmlParser(data.regras)}
                        </div>
                        <p>{data.copyright}</p>
                    </div>                    
                </div>

                <Disclaimer data={data} />
                
            </footer>
        
        );

    }

}

export default withRouter(Footer)