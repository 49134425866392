import React, { Component } from 'react'
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import Menu from './Menu';
import logoBBPrev from '../../assets/images/logo-bbprev.png';
import $ from 'jquery';

export class Header extends Component {

    constructor( props ){

        super();

        this.state = {
            subSite: false,
            menuOpened: false,
            isMobile: false
        };
        this.timeResize = false;
        this.$toggle = $( '.menu-toggle' );

        this.setMenuState = this.setMenuState.bind( this );

    }

    setMenuState = ( menuOpened ) => {
        this.setState({
            menuOpened: menuOpened
        });
    }
    
    componentDidMount(){

        this.checkSize();

        // Bind when router changes
        const { history } = this.props;
        // First time
        this.checkTitle( history.location );
        // Binder
        history.listen((location) => { 
            this.checkTitle( location );
        });

        window.addEventListener( 
            'resize', 
            () => {
                if(  this.timeResize ) clearTimeout( this.timeResize );
                this.timeResize = this.checkSize();
            }, 
            true 
        );
    
    }

    shouldComponentUpdate

    checkTitle( location ){
        const subSite = ( location.pathname.indexOf( '/pensefuturo' ) === 0 ) ? 'pensefuturo' : false;
        this.setState({ subSite });
    }

    componentWillUnmount(){
        window.removeEventListener( 'resize', this.checkSize );
    }

    checkSize = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.setState({
            isMobile: width <= 960
        })
    }

    clickToggle = ( event ) => {
        
        this.setState( state => {
            return {
                menuOpened: state.menuOpened ? false : true
            }
        });

    };

    render(){

        const 
            { name, key, id, logo } = this.props.siteData,
            { subSite, menuOpened, isMobile } = this.state,
            //plainLogo = ( logo !== undefined && logo !== false ) ? logo : false,
            plainLogo = false,
            menuClass = menuOpened ? 'opened' : '',
            notFound = !id,
            headerClass = 'App-header' + ( notFound ? ' not-found' : '' ) + ( logo !== undefined ? ' with-logo' : '' ),
            API_URL = process.env.REACT_APP_API_URL
        ;

        // Home link
        var homeLink = false;
        if( subSite === 'pensefuturo' ){
            homeLink = {
                title : 'Pense Futuro',
                url : '/pensefuturo'
            };
        }
        else if( key !== 'home' ){
            homeLink = {
                title : name,
                url : '/'
            };
        }
        
        return (
            <header className={headerClass} id="masthead">
                <div className="wcf">
                    <a href={API_URL} title="BB Previdência">
                        <h1>
                            <img src={logoBBPrev} className="App-logo" alt="logo" />
                        </h1>
                    </a>
                    {
                        homeLink
                        ? <h2>
                            <NavLink to={homeLink.url} title={homeLink.title}>
                                {plainLogo && <img src={logo} alt={name} />}
                                {!plainLogo && homeLink.title }
                            </NavLink>
                        </h2>
                        : null
                    }
                    <div id="menu-wrapper" className={menuClass}>
                        {
                            notFound
                            ? ''
                            : <Menu siteData={this.props.siteData} menuOpened={menuOpened} isMobile={isMobile} setMenuState={this.setMenuState} />
                        }
                        <a href="https://login.bbprevidencia.com.br" className="button" title="Minha Previdência" target="_blank" rel="noreferrer">Minha Previdência</a>
                    </div>
                </div>
                <div className={ 'menu-toggle ' + menuClass } onClick={this.clickToggle}>
                    <span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div>
            </header>
        );

    }

}

export default withRouter( Header );