import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import axios from 'axios';
// GTM
import ReactGA from 'react-ga4';
// Structure
import PikiLoader from './vendor/PikiLoader/PikiLoader';
import Navigation from './scripts/helpers/Navigation';
import Header from './scripts/structure/Header';
import Footer from './scripts/structure/Footer';
// Home
import Home from './scripts/pages/Home';
import Planos from './scripts/pages/Planos';
// Planos
import Plano from './scripts/pages/Plano';
//import Simulador from './scripts/pages/Simulador';
//import Adesao from './scripts/pages/Adesao';
// Common
import FaleConosco from './scripts/pages/FaleConosco';
import Comunicados from './scripts/pages/Comunicados';
import ClubeDeBeneficios from './scripts/pages/ClubeDeBeneficios';
import PenseFuturo from './scripts/pages/PenseFuturo';
import PenseFuturoCateg from './scripts/pages/PenseFuturoCateg';
import PenseFuturoSingle from './scripts/pages/PenseFuturoSingle';
import Documentos from './scripts/pages/Documentos';
import DemonstrativosFormularios from './scripts/pages/DemonstrativosFormularios';
import PerguntasFrequentes from './scripts/pages/PerguntasFrequentes';
import Glossario from './scripts/pages/Glossario';
import Page from './scripts/pages/Page';
import NotFound from './scripts/pages/NotFound';

const API_URL = process.env.REACT_APP_API_URL;

const Analytics = withRouter(( props ) => {

    const defaultGTM = 'G-GRB9XZ6ZG5';
    
    useEffect(() => {

        setTimeout( () => {
            
            let _g4_title = 'BB Previdência';

            // Main title
            const pageTitle = document.querySelector('.page-title');
            if( pageTitle ){
                _g4_title += ( ' | ' + pageTitle.innerText );
            }
        
            // Inner title
            const innerTitle = document.querySelector('article h2:not(.page-title)');
            if( innerTitle ){
                _g4_title += ( ' | ' + innerTitle.innerText );
            }

            ReactGA.initialize( defaultGTM );
            ReactGA.send({ hitType: "pageview", page: props.location.pathname, title: _g4_title });
    
            
        }, 1000 );
        
    });
        
    return('');

})

function App(){

    const [ basename, setBasename ] = useState( '/' );
    const [ isPlain, swithIsplain ] = useState( null );
    const [ siteData, setSiteData ] = useState( null );
    
    useEffect(() => {

        function receivePlans( planos ){
            
            window.planos = planos;
            window.isHome = true;
            window.siteID = false;
            window.isFrontPage = document.location.href.replace( 'https', '' ).replace( 'http', '' ) === process.env.REACT_APP_API_URL.replace( 'https', '' ).replace( 'http', '' );
            
            // Check if is plain page
            let machedPlain = false;
            if( window.siteKey !== '' ){
            
                planos.forEach(function( plano ){

                    if( plano.sitekey === window.siteKey ){
                        window.siteID = plano.id;
                        window.isHome = false;
                        machedPlain = plano.sitekey;
                    }
                
                });
            
            }

            swithIsplain( machedPlain !== false );

        }

        axios
            .get( API_URL + '/data/planos.json' )
            .then(function(response){
                receivePlans( response.data );
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            })
        ;

    }, [] );
    
    useEffect(() => {

        if( isPlain !== null ){

            let ENDPOINT;
            if( isPlain ){
                ENDPOINT = API_URL + '/data/' + window.siteKey + '/home.json';
            }
            else {
                ENDPOINT = API_URL + '/data/home.json';
            }

            // Request structure data
            axios
                .get( ENDPOINT )
                .then( ( res ) => {

                    window.siteID = res.data.id;

                    // Set basename
                    setBasename( isPlain ? window.siteKey : '/' );

                    // Keep data
                    setSiteData( res.data );

                    closeLoader();

                    // Habilitar simulador e adesão
                    // const enableJoin = isPlain && ( typeof siteData.join !== 'undefined' && siteData.join.enabled === true );    
                
                })
                .catch( ( err ) => {

                    console.log( err ) 
                    setSiteData( false );
                
                })
                .finally(function () {
                    closeLoader();
                })
            ;
        
        }
    
    }, [ isPlain ]);

    function closeLoader(){
        const Loader = document.getElementById( 'main-loader' );
        if( Loader ){
            Loader.parentNode.removeChild( Loader );
        }
    }

    // Not found
    if( siteData === null ){
        return null;
    }
    else if( siteData === false ){
        return(
            <Router>    
                <Navigation />
                <Header siteData={false} />
                <NotFound siteID={false} />
            </Router>
        )
    }
    else {

        return (
            <HelmetProvider>
                <Router basename={basename}>
                    <PikiLoader />
                    <Navigation />
                    <Header siteData={siteData} />
                    <Switch>
                        <Route exact path="/fale-conosco">
                            <FaleConosco siteData={siteData} />
                        </Route>
                        <Route exact path="/planos">
                            <Planos />
                        </Route>
                        {/* enableJoin &&
                        <Route exact path="/simulador">
                            <Simulador siteData={siteData} />
                        </Route> */}
                        {/* enableJoin &&
                        <Route exact path="/adesao">
                            <Adesao siteData={siteData} />
                        </Route> */}
                        {/* enableJoin &&
                        <Route path="/adesao/:stepKey">
                            <Adesao siteData={siteData} />
                        </Route> */}
                        <Route exact path="/comunicados">
                            <Comunicados />
                        </Route>
                        <Route path="/comunicados/:comunicadoID">
                            <Comunicados />
                        </Route>
                        <Route exact path="/clube-de-beneficios">
                            <ClubeDeBeneficios data={siteData} />
                        </Route>
                        <Route exact path="/pensefuturo">
                            <PenseFuturo data={siteData.pensefuturo} />
                        </Route>
                        <Route exact path="/pensefuturo/glossario">
                            <Glossario />
                        </Route>
                        <Route path="/pensefuturo/:category/:itemID">
                            <PenseFuturoSingle />
                        </Route>
                        <Route path="/pensefuturo/:category">
                            <PenseFuturoCateg />
                        </Route>
                        <Route exact path="/documentos">
                            <Documentos />
                        </Route>
                        <Route exact path="/demonstrativos-e-formularios">
                            <DemonstrativosFormularios />
                        </Route>
                        <Route path="/demonstrativos-e-formularios/:groupID">
                            <DemonstrativosFormularios />
                        </Route>
                        <Route exact path="/perguntas-frequentes">
                            <PerguntasFrequentes siteData={siteData} />
                        </Route>
                        <Route exact path="/">
                            {
                                window.isHome
                                ? <Home siteData={siteData} />
                                : <Plano siteData={siteData} />
                            }
                        </Route>
                        <Route exact path="*">
                            <Page siteID={siteData.id} />
                        </Route>
                    </Switch>
                    <Footer siteData={siteData} />
                    <Analytics />
                </Router>
            </HelmetProvider>
        )
    
    }

}

export default App;