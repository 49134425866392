import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { penseFuturoLink } from '../helpers/Common';
import TimeDeEspecialistas from '../blocks/TimeDeEspecialistas';
import Teaser from '../blocks/Teaser';
import ListFilters from '../forms/ListFilters';
import LoaderMask from '../helpers/LoaderMask';
import scrollTo from '../helpers/ScrollTo';
import $ from 'jquery';

export class PenseFuturoCateg extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        perPage: 12,
        items: [],
        loadingItems: true,
        editorias: [],
        palavraChave: '',
        values : {
            keyword : '',
            categoria : '0',
        },
        totalPages : 1,
        paged: 1,
        mask: new LoaderMask(),
        isLoaded: false,
        showActives: false
    };

    constructor(props){

        super(props);
    
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind( this );
        this.bindPager = this.bindPager.bind( this );
        this.bindSubmit = this.bindSubmit.bind(this);
        this.clearFilter = this.clearFilter.bind( this );

    }

    getValue( key ){
        const { values } = this.state;
        return values[ key ];
    }

    setValue( key, value ){

        var { values } = this.state;

        values[ key ] = value;
        this.setState({
            values : values
        });

        if( key === 'categoria' ){
            this.requestItems();
        }

    }

    bindSubmit( event ){

        event.preventDefault();

        this.setState(
            {
                palavraChave : this.getValue( 'keyword' ),
                showActives : true
            }, 
            this.requestItems 
        );
        
    }

    /*
    clearFilter(){

        var { items } = this.state;

        this.setState({
            filtered : items,
            showActives : false,
            values : {
                keyword : ''
            }
        });

    }
    */

    componentDidMount(){

        // Request items
        this.requestItems();

        // Select de editorias
        this.getEditorias();
    }

    // Select de editorias
    getEditorias(){

        const 
            SERVER_URL = process.env.REACT_APP_SERVER_URL,
            { match } = this.props
        ;
        axios
            .get( SERVER_URL + 'wp-json/wp/v2/' + ( match.params.category === 'curadoria' ? 'categs' : 'editorias' ) + '?per_page=100' )
            .then( res => {
                
                let items = [{
                    id : 0,
                    text : 'Todas as categorias'
                }];
                for( let i = 0; i < res.data.length; i++ ){
                    items.push({
                        id : res.data[ i ].id,
                        text : res.data[ i ].name
                    });
                }

                this.setState({
                    isLoaded: ( this.state.items.length ? true : false ),
                    editorias: items
                });
            
            })
            .catch(err => console.log(err))
        ; 

    }

    getURL( category, paged ){

        const 
            SERVER_URL = process.env.REACT_APP_SERVER_URL,
            { perPage } = this.state
        ;
        return  SERVER_URL + 'wp-json/wp/v2/' + ( category === 'curadoria' ? 'curadoria' : 'pense-futuro' ) + '?per_page='+ perPage +'&page='+ paged +'&siteID=' + window.siteID

    }

    bindPager( e ){

        e.preventDefault();

        const { paged } = this.state;
        this.setState({
            paged: ( paged + 1 )
        }, this.requestItems );

    }

    requestItems(){

        const 
            { paged, values, palavraChave  } = this.state,
            { match } = this.props
        ;
        var URL = this.getURL( match.params.category, paged );

        // Open mask
        this.state.mask.open();

        // Loading
        this.setState({ loadingItems: true });

        // Category
        if( values.categoria !== '' ){
            URL += '&categoria=' + values.categoria;
        }
        // Keyword
        if( palavraChave !== '' ){
            URL += '&palavra-chave=' + palavraChave;
        }
        
        // Request
        axios
            .get( URL )
            .then( res => {

                // Insert new items
                let { items } = this.state;
                const nextFirstIndice = items.length;
                items = items.concat( res.data );

                this.setState(
                    {
                        isLoaded: ( this.state.editorias.length ? true : false ),
                        loadingItems: false,
                        items: items,
                        totalPages: res.headers[ 'x-wp-totalpages' ]
                    }, 
                    () => {

                        // Close mask
                        this.state.mask.close();

                        // Scroll to next intems
                        const nextTarget = $( '.teasers-list li' ).eq(nextFirstIndice );
                        scrollTo( nextTarget, 30 );
                    
                    }
                );
            })
            .catch(err => console.log(err))
        ;

    }

    clearFilter(){

        var { values } = this.state; 
        values.keyword = '';

        this.setState(
            {
                palavraChave : '',
                values : values,
                showActives : false
            },
            this.requestItems
        );

    }

    render() {

        const 
            { match, history } = this.props,
            { items, editorias, palavraChave, totalPages, paged, loadingItems, isLoaded } = this.state
        ;

        if( !isLoaded ) return null;

        var _title = ( match.params.category === 'curadoria' ? 'Curadoria Pense Futuro' : 'Pense Futuro' );

        return (
            <React.Fragment>
                <div className={ 'page content-list ' + match.params.category }>
                    <Helmet>
                        <title>{ _title  + ' | BB Previdência' }</title>
                        <meta property="og:title" content={_title} />
                    </Helmet>
                    <header>
                        <div>
                            <div className="left">
                                <button className="home" onClick={ () => { history.push('/'); } } title="Início">Início</button>
                                <button className="styled size--2 color--2 back" onClick={history.goBack} title="Voltar">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="12.01" viewBox="0 0 11.995 12.01"><path id="Arrow" d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1.034,1.034,0,0,0,0,1.42l5,5a1,1,0,1,0,1.42-1.42L9.41,13H17a1,1,0,0,0,0-2Z" transform="translate(-6.005 -5.996)" fill="#0059a5"/></svg>
                                    <span>Voltar</span>
                                </button>
                                <h2>{_title}</h2>
                            </div>
                            <ListFilters
                                terms={editorias}
                                getValue={this.getValue}
                                setValue={this.setValue}
                                bindSubmit={this.bindSubmit}
                            />
                        </div>
                    </header>
                    { ( palavraChave !== '' /*&& showActives*/ ) && 
                    <div className="active-filters">
                        <em>Mostrando resultados para <strong>“{palavraChave}”</strong></em>
                        <button className="inline" title="Limpar filtro" onClick={this.clearFilter}>Limpar filtro</button>
                    </div>}
                    { 
                    ( !items.length )
                    ? <div className="no-results show">Nenhum resultado foi encontrado.</div>
                    : <div className="teasers-list">
                        <ul>
                            {items.map( ( item ) => {
                                const url = penseFuturoLink( item );
                                return(
                                    <Teaser key={'item-'+item.id} data={item} url={url} type="2"></Teaser>
                                );
                            })}
                        </ul>
                        { ( totalPages > 1 && paged < totalPages ) &&
                        <button type="button" className="styled default pager" onClick={this.bindPager}>{loadingItems ? 'Carregando...' : 'Carregar mais conteúdos'}</button>}
                    </div>
                    }
                </div>
                <TimeDeEspecialistas />
            </React.Fragment>
        );
  
    }

}
  
export default withRouter( PenseFuturoCateg );