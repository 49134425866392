import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";

import { penseFuturoLink } from '../helpers/Common';

export class PenseFuturoSlider extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            items: []
        }
    
    }

    componentDidMount () {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios.get( SERVER_URL + 'wp-json/bbprev/v1/pense-futuro-destaques?type=2' )
            .then(res => this.setState({
                isLoaded: true,
                items: res.data
            }))
            .catch(err => console.log(err))
        ;
        
    }

    render() {

        const { items, isLoaded } = this.state;
        if( !isLoaded || !items.length ) return null;

        const total = items.length;
        const slideCount = total < 3 ? total : 3;
        var slickSettings;
        
        if(  slideCount === 1 ){
            slickSettings = {};
        }
        else {
            slickSettings = {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: 1,
                autoplaySpeed: 4000,
                adaptiveHeight: true,
                arrows: true,
                dots: true,
            };
        }

        const classnames = 'list-items adaptive-height' + ( items.length > 1 ? ' with-pager' : '' );
        
        return (
            <section id="pense-futuro-slider" className="pense-futuro-slider">
                <h3>Conteúdos em destaque</h3>
                <Slider {...slickSettings} className={classnames}>
                    {items.map( ( item ) => {

                        // URL
                        const url = penseFuturoLink( item );

                        // Thumb url
                        var thumb_url = false;
                        if( item.custom.thumb ){
                            thumb_url = item.custom.thumb;
                        }
                        else if( item.custom.video ){
                            thumb_url = 'https://img.youtube.com/vi/'+ item.custom.video +'/3.jpg';
                        }

                        return(
                            <div key={item.ID} data-id={item.ID} className={ 'list-item' + ( thumb_url ? ' with-image' : '' ) }>
                                {thumb_url && <NavLink to={url} className="image-wrapper">
                                    <picture>
                                        <img src={thumb_url} alt={item.title.rendered} />
                                        {item.custom.video && <span className="video-icon"></span>}
                                    </picture>
                                </NavLink>}
                                <NavLink to={url} className="texts">
                                    <h4 className="title">{item.title.rendered}</h4>
                                    <p className="desc">{item.custom.excerpt}</p>
                                </NavLink>
                            </div>
                        );

                    })}
                </Slider>
            </section>
        )
   
    }

}

export default withRouter( PenseFuturoSlider );