import React, { Component } from 'react';
import { withRouter } from "react-router";
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { API } from '../helpers/API';
import PenseFuturoSlider from '../blocks/PenseFuturoSlider';
import PenseFuturoLasts from '../blocks/PenseFuturoLasts';
import CuradoriaLasts from '../blocks/CuradoriaLasts';
import TimeDeEspecialistas from '../blocks/TimeDeEspecialistas';
import Glossario from '../blocks/Glossario';

export class PenseFuturo extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    };

    constructor( props ){

        super();
        this.state = {
            isLoaded : false,
            data : false
        };

    }

    async componentDidMount(){

        const data = await API.Common.getPageData( 'pensefuturo' );
        this.setState({
            data: data,
            isLoaded: true
        })
    }

    render() {

        const { isLoaded, data } = this.state;
        if( !isLoaded ) return null;

        var _title = 'Pense Futuro | BB Previdência';

        return (
            <React.Fragment>
                <div id="pensefuturo" className="page pense-futuro">
                    <Helmet>
                        <title>{_title}</title>
                        <meta property="og:title" content={_title} />
                    </Helmet>
                    <header className="cover">
                        <div>
                            <h2>{data.post_title}</h2>
                            <div>
                                {ReactHtmlParser(data.post_content)}
                            </div>
                        </div>
                    </header>
                    <PenseFuturoSlider />
                    <PenseFuturoLasts />
                    <CuradoriaLasts />
                </div>
                <style>
                    {data.cover && '#pensefuturo>header{background-image:url('+data.cover+');}'}
                    {data.cover_mobile && '@media(max-width:540px){#pensefuturo>header{background-image:url('+data.cover_mobile+');}}'}
                </style>
                <Glossario />
                <TimeDeEspecialistas />
            </React.Fragment>
        );
  
    }

}
  
export default withRouter( PenseFuturo );