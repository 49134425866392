import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import $ from 'jquery';

import Icons from "../helpers/Icons";

export class Menu extends Component {

    constructor( props ){

        super( props );
        
        this.state = {
            $: false,
            $items : false
        };
        
        this.toggleMenu = this.toggleMenu.bind( this );
    
    }

    componentDidMount(){

        const $menu = $( '#menu' );

        this.setState({
            $: $menu,
            $items: $( '> li', $menu )
        });
    
    }

    // Bind menu close
    bindMenuClose = ( e ) => {

        // Clicked
        e.target.$ = e.target.$ || $( e.target );

        // Menu parent
        e.target.$menu = e.target.$menu || e.target.$.parents( '#menu' );

        // If clicked in button, not force close
        if( !e.target.$menu.length ){
            this.menuClose( e.target.$ );
        }

    }

    // Close menus
    menuClose = ( $target ) => {

        // Remove binder click
        window.removeEventListener( 'mousedown', this.bindMenuClose );
        
        // Deactivate menu
        this.state.$items.removeClass( 'active' );
        
        // Close all menu when mobile
        if( $target !== undefined && this.props.isMobile ){
            this.props.setMenuState( false );
        }
    
    }

    // Open menu item
    menuOpen = ( $element ) => {
        // Activate item
        $element.addClass( 'active' );
        // Bind all window clicks to close
        window.addEventListener( 'mousedown', this.bindMenuClose );
    }

    // Toggle menu click
    toggleMenu( e ){
    
        e.preventDefault();

        // Get target once
        e.target.$p = e.target.$p || $( e.target ).parents( 'li' ).first();
        // Verify if is active
        let active = e.target.$p.hasClass( 'active' );
        // Close all menu
        this.menuClose();
        // If not active, activate
        if( !active ){
            this.menuOpen( e.target.$p );
        }

    }

    render() {

        const { name, simule, adesao, pages } = this.props.siteData;
        const isHome = window.isHome;
        const isFrontPage = window.isFrontPage;

        return (
            <ul id="menu">
                {/* <li>
                    <NavLink to="/fale-conosco" onClick={this.menuClose} activeClassName='is-active'>Fale Conosco</NavLink>
                </li> */}
                {
                    isHome ? 
                    <li>
                        <button onClick={this.toggleMenu} title="Institucional">
                            Institucional
                            <Icons tag="arrowdown" />
                        </button>
                        <nav>
                            {pages.map(page=>
                                <NavLink to={ '/' + page.slug } onClick={this.menuClose} activeClassName='is-active' key={page.id}>{page.title}</NavLink>
                            )}
                        </nav>
                    </li>
                    : <li>
                        <button onClick={this.toggleMenu} title="Conheça">
                            Conheça
                            <Icons tag="arrowdown" />
                        </button>
                        <nav>
                            <NavLink to="/#o-plano" onClick={this.menuClose} activeClassName='is-active'>{name}</NavLink>
                            <NavLink to="/#beneficios" onClick={this.menuClose} activeClassName='is-active'>Benefícios</NavLink>
                            { !isHome && simule.code ? <NavLink to="/#simuladores" onClick={this.menuClose} activeClassName='is-active'>Simule agora</NavLink> : null }
                            { !isHome && adesao.code ? <NavLink to="/#simuladores" onClick={this.menuClose} activeClassName='is-active'>Faça sua adesão</NavLink> : null }
                        </nav>
                    </li>
                }
                <li>
                    <button onClick={this.toggleMenu} title="Informe-se">
                        Informe-se
                        <Icons tag="arrowdown" />
                    </button>
                    <nav>
                        {/*<NavLink to="/#noticias" onClick={this.menuClose} activeClassName='is-active'>Notícias</NavLink>*/}
                        <NavLink to="/fale-conosco" onClick={this.menuClose} activeClassName='is-active'>Fale Conosco</NavLink>
                        <NavLink to={isFrontPage?'/#comunicados':'/comunicados'} onClick={this.menuClose} activeClassName='is-active'>Comunicados</NavLink>
                        <NavLink to={isFrontPage?'/#pensefuturo':'/pensefuturo'} onClick={this.menuClose} activeClassName='is-active'>Pense Futuro</NavLink>
                        {/* <NavLink to="/#educacao-financeira" onClick={this.menuClose} activeClassName='is-active'>Educação Financeira</NavLink> */}
                        <NavLink to="/#central-de-informacoes" onClick={this.menuClose} activeClassName='is-active'>Central de informações</NavLink>
                    </nav>
                </li>
            </ul>
        );

    }

}

export default Menu